
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "ReportSRegister",
  components: {},
  setup() {
    let data = reactive<any>({
      formInline: {
        source: "",
      },
      tableData: [],
    });

    const RegisterReportByOperator = () => {
      Api.Api2.RegisterReportByOperator(
        data.formInline.source ? data.formInline.source : "my"
      ).then((res: any) => {
        console.log(res);
        if (res.code == 0) {
          data.tableData = res.data;
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    onMounted(() => {
      RegisterReportByOperator();
    });

    return {
      ...toRefs(data),
      RegisterReportByOperator,
    };
  },
});
