import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_form, {
              inline: true,
              model: _ctx.formInline
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, { label: "来源" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.formInline.source,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formInline.source) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      onClick: _ctx.RegisterReportByOperator
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("查询")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_table, {
              data: _ctx.tableData,
              stripe: "",
              border: "",
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  prop: "y",
                  label: "年"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "m",
                  label: "月"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "tdr",
                  label: "提单人"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "未确认",
                  label: "未确认"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "院方受理",
                  label: "院方受理"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "院方废弃",
                  label: "院方废弃"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "完成提醒回访",
                  label: "完成提醒回访"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "完成废弃回访",
                  label: "完成废弃回访"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "完成订单",
                  label: "完成订单"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "违约",
                  label: "违约"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "停诊",
                  label: "停诊"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "用户取消",
                  label: "用户取消"
                })
              ]),
              _: 1
            }, 8, ["data"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}